import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import "./config/i18next"; // Your i18n setup file
import i18next from "i18next";

import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import Search from "./routes/Search";
import Root from "./routes/Root";
import "./config/i18next";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import AuthStyle from "./AuthStyle";

import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";

import theme from "./theme";
import amplifyconfig from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { Authenticator, Flex, Heading, Image } from "@aws-amplify/ui-react";

import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import SessionProvider, { useUserSession } from "./context/SessionProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { toast, Toaster } from "sonner";
import { SWRConfig } from "swr";

const Exercise = lazy(() => import("./routes/Excersise"));
const User = lazy(() => import("./routes/User"));
const Workout = lazy(() => import("./routes/Workout"));
const Home = lazy(() => import("./routes/Home"));
const Weight = lazy(() => import("./routes/Weight"));
const YourLibrary = lazy(() => import("./routes/YourLibrary"));
const Top50WorldPopularWorkouts = lazy(() =>
  import("./routes/Top50WorldPopularWorkouts")
);
const Top50WorldPopularWorkoutsCountry = lazy(() =>
  import("./routes/Top50WorldPopularWorkoutsCountry")
);
const Routine = lazy(() => import("./routes/Routine"));
const Diet = lazy(() => import("./routes/Diet"));
const Kcal = lazy(() => import("./routes/Kcal"));
const LandingPage = lazy(() => import("./routes/LandingPage"));
const Creator = lazy(() => import("./routes/Settings/Creator"));
const Personal = lazy(() => import("./routes/Settings/Personal"));

// Less accessed routes
const UpdateSettings = lazy(() => import("./routes/Settings"));
const Water = lazy(() => import("./routes/Water"));
const Create = lazy(() => import("./routes/Create"));
const NotFound = lazy(() => import("./routes/NotFound"));
const PrivacyPolicy = lazy(() => import("./routes/privacy-policy"));
const CreateRoutine = lazy(() => import("./routes/Create/Routine"));
const CreateWorkout = lazy(() => import("./routes/Create/Workout"));
const CreateExercise = lazy(() => import("./routes/Create/Exercise"));
const Premium = lazy(() => import("./routes/Premium"));

Amplify.configure(amplifyconfig);

// Set Amplify translations
I18n.putVocabularies(translations);

// Function to sync Amplify language with i18next
const syncI18nWithAmplify = () => {
  I18n.setLanguage(i18next.language);
};

// Wait for i18next to initialize before syncing
i18next.init().then(() => {
  syncI18nWithAmplify();
});

// Listen for i18next language changes
i18next.on("languageChanged", (lng) => {
  I18n.setLanguage(lng);
});

function ProtectedCom({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRedirected, setIsRedirected] = useState(false); // Prevent multiple redirects

  if (
    location.pathname !== "/app" &&
    !sessionStorage.getItem("redirectUrl") &&
    !isRedirected
  ) {
    sessionStorage.setItem("redirectUrl", location.pathname + location.search);
  }

  useEffect(() => {
    // Extract the query parameters from the URL
    const params = new URLSearchParams(location.search);
    const errorDescription = params.get("error_description");
    if (errorDescription) {
      // Decode the URL-encoded error description
      const decodedError = decodeURIComponent(errorDescription);
      toast.error(decodedError.split("error")[1]);
    }

    // Handle referral code
    const referralCode = params.get("ref");
    if (referralCode) {
      // Save the referral code to localStorage
      localStorage.setItem("referralCode", referralCode);
    }
  }, [location]);

  return (
    <AuthStyle>
      <Authenticator components={components} variation="modal">
        {({ user }) => {
          // Ensure that redirect happens only after successful login
          if (user && !isRedirected) {
            // Only redirect once after the user has logged in and hasn't been redirected yet
            const redirectUrl = sessionStorage.getItem("redirectUrl");
            sessionStorage.removeItem("redirectUrl"); // Clean up after redirect

            navigate(redirectUrl); // Redirect to the stored URL
            setIsRedirected(true); // Set this flag so we don't redirect again
          }

          return (
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                revalidateIfStale: false,
                fetcher: (resource, init) =>
                  fetch(resource, init).then((res) => res.json()),
              }}
            >
              <SessionProvider user={user}>{children}</SessionProvider>
            </SWRConfig>
          );
        }}
      </Authenticator>
    </AuthStyle>
  );
}

export default ProtectedCom;

const SuspenseWrapper = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={30} sx={{ alignSelf: "center" }} />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

const router = createBrowserRouter([
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: (
      <ThemeProvider theme={theme}>
        <LandingPage />
      </ThemeProvider>
    ),
  },
  {
    path: "/app",
    element: (
      <ThemeProvider theme={theme}>
        <ProtectedCom>
          <Root />
        </ProtectedCom>
      </ThemeProvider>
    ),
    children: [
      {
        path: "", // This will match "/app"
        element: <Home />,
      },
      {
        path: "diet/:customer_id", // This will match "/app/diet/:customer_id"
        element: (
          <SuspenseWrapper>
            <Diet />
          </SuspenseWrapper>
        ),
      },
      {
        path: "weight/:customer_id",
        element: (
          <SuspenseWrapper>
            <Weight />
          </SuspenseWrapper>
        ),
      },
      {
        path: "kcal/:customer_id",
        element: (
          <SuspenseWrapper>
            <Kcal />
          </SuspenseWrapper>
        ),
      },
      {
        path: "water/:customer_id",
        element: (
          <SuspenseWrapper>
            <Water />
          </SuspenseWrapper>
        ),
      },
      {
        path: "search",
        element: (
          <SuspenseWrapper>
            <Search />
          </SuspenseWrapper>
        ),
      },
      {
        path: "premium",
        element: (
          <SuspenseWrapper>
            <Premium />
          </SuspenseWrapper>
        ),
      },
      {
        path: "library",
        element: (
          <SuspenseWrapper>
            <YourLibrary />
          </SuspenseWrapper>
        ),
      },
      {
        path: "create",
        element: (
          <SuspenseWrapper>
            <Create />
          </SuspenseWrapper>
        ),
      },
      {
        path: "create/exercise",
        element: (
          <SuspenseWrapper>
            <CreateExercise />
          </SuspenseWrapper>
        ),
      },
      {
        path: "create/workout",
        element: (
          <SuspenseWrapper>
            <CreateWorkout />
          </SuspenseWrapper>
        ),
      },
      {
        path: "create/routine",
        element: (
          <SuspenseWrapper>
            <CreateRoutine />
          </SuspenseWrapper>
        ),
      },
      {
        path: "exercise/:exercise_id",
        element: (
          <SuspenseWrapper>
            <Exercise />
          </SuspenseWrapper>
        ),
      },
      {
        path: "workout/:workout_id",
        element: (
          <SuspenseWrapper>
            <Workout />
          </SuspenseWrapper>
        ),
      },
      {
        path: "routine/:routine_id",
        element: (
          <SuspenseWrapper>
            <Routine />
          </SuspenseWrapper>
        ),
      },
      {
        path: "user/:user_id",
        element: (
          <SuspenseWrapper>
            <User />
          </SuspenseWrapper>
        ),
      },
      {
        path: "Top50WorldPopularWorkouts",
        element: (
          <SuspenseWrapper>
            <Top50WorldPopularWorkouts />
          </SuspenseWrapper>
        ),
      },
      {
        path: "top50mostExecutedWorkoutsCountry/:country_id",
        element: (
          <SuspenseWrapper>
            <Top50WorldPopularWorkoutsCountry />
          </SuspenseWrapper>
        ),
      },
      {
        path: "settings",
        element: (
          <SuspenseWrapper>
            <UpdateSettings />
          </SuspenseWrapper>
        ),
        children: [
          {
            path: "creator",
            element: (
              <SuspenseWrapper>
                <Creator />
              </SuspenseWrapper>
            ),
          },
          {
            path: "personal",
            element: (
              <SuspenseWrapper>
                <Personal />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: "*",
        element: (
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        ),
      },
    ],
  },
]);

const components = {
  SignIn: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
          //border="1px solid red"
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
            /* border="1px solid red" */
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            /*             border="1px solid red"
             */
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
  },
  SignUp: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            maxWidth="80%"
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
    Footer() {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <span>
            By signing up, you agree{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              style={{ color: "black" }}
            >
              {"Privacy Policy"}
            </a>
            .
          </span>
        </div>
      );
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Suspense fallback={<></>}>
      <Toaster position="top-center" richColors closeButton expand={false} />
      <CssBaseline />
      <RouterProvider router={router} />
    </Suspense>
  </>
);
