import { createContext, useContext, useEffect, useRef, useState } from "react";
import { API_BASE_URL, IMG_BASE_URL, fetchUserSession } from "../utils";
import {
  Box,
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import WorkoutExerciseTableExecution from "../components/WorkoutExerciseTableExecution";
import { toast } from "sonner";
import { postLastEce } from "../utils/exerciseUtils";
import AlarmIcon from "@mui/icons-material/Alarm";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import UploadIcon from "@mui/icons-material/Upload";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/thumbs"; // Import thumbs styles
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { fetchAuthSession } from "aws-amplify/auth";

/**

{
  uuidexercise:[
    {
      12: [
          {
          utc:"123123123",
          rep: 12
          }
        ]
    }
  ]
}

*/

export const SessionContext = createContext();

export const useUserSession = () => {
  return useContext(SessionContext);
};

export const useUserJwt = () => {
  const ctx = useContext(SessionContext);
  return ctx && ctx.jwt ? ctx.jwt : null;
};

export const jwtRefresher = async (ctx) => {
  if (!ctx || !ctx.jwt) {
    return null;
  }

  const isTokenExpired = () => {
    try {
      const { exp } = jwtDecode(ctx.jwt); // Decode the token to extract expiration time
      const currentTime = Math.floor(Date.now() / 1000) + 100; // Current time in seconds
      return exp < currentTime; // Token is expired if current time is greater than exp
    } catch (err) {
      console.error("Error decoding JWT:", err);
      return true; // Treat as expired if decoding fails
    }
  };

  if (isTokenExpired()) {
    // Optionally, regenerate the token here
    console.log("Token is expired. Regenerating...");
    const session = await fetchAuthSession();
    return session.tokens.idToken.toString();
  }

  return ctx.jwt;
};

export async function executeWorkout({
  workout_id,
  jwt,
  country_id,
  setWorkoutPlayer,
}) {
  console.log("execute workout executed: ");
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwt);
    myHeaders.append("Content-Type", "application/json");

    const body = JSON.stringify({
      country_id, // Include the country_id in the request body
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body, // Add the body to the request options
      redirect: "follow",
    };

    const res = await fetch(
      API_BASE_URL + "/executeWorkout/" + workout_id,
      requestOptions
    );

    console.log("executed workout res: ", res);

    if (!res.ok) {
      const resJson = await res.json();
      toast.error(
        resJson.errMsg || "There was an error executing the workout."
      );
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error("the error in workout execution", error);
    toast.error(error.errMsg || "There was an error executing the workout.");
  }
}

const SessionProvider = ({ children, user }) => {
  const [session, setSession] = useState(null);
  const [workoutPlayer, setWorkoutPlayer] = useState({
    display: false,
    workout: null,
    exercises: null,
  });
  const { t } = useTranslation(["workoutTraining"]);
  let [updateState, setUpdateState] = useState(0);
  let [updateInterval, setUpdateInterval] = useState(null);

  let interval = useRef(null);

  let process = useRef({
    countdown: null,
    duration: null,
    rest: null,
    exerciseCounter: 0,
    exerciseCounterMax: null,
    stage: null,
    workout: null,
    exercises: null,
    time: null,
    totalTime: null,
    restMax: null,
    durationMax: null,
    countdownMax: null,
  });

  /**
   * This use effect initialize the initial process ref
   * which is used as the global state of the interval to
   * execute a workout is saved. We use a ref because the state
   * in this type of interval execution gives much problems.
   */
  useEffect(() => {
    if (workoutPlayer.workout && workoutPlayer.exercises) {
      console.log("init process: ", workoutPlayer.workout);
      let totalTime = workoutPlayer.exercises.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.time.totalTime;
        },
        0
      );
      const exArr = workoutPlayer.exercises[process.current.exerciseCounter];
      process.current = {
        ...process.current,
        stage: "countdown",
        exerciseCounterMax: exArr.length,
        rest: exArr.time.rest,
        duration: exArr.time.duration,
        countdown: exArr.time.countdown,
        restMax: exArr.time.rest,
        durationMax: exArr.time.duration,
        countdownMax: exArr.time.countdown,
        time: 0,
        totalTime: totalTime,
        exerciseCounter: 0,
        workout: workoutPlayer.workout,
        exercises: workoutPlayer.exercises,
        intervalActive: true,
        changedManually: false,
      };
      setUpdateState(Math.random());
    }
  }, [workoutPlayer.exercises, workoutPlayer.workout]);
  /**
   * This useEffect detects that the ref has been already
   * initialized and if the interval ref doesn't exists
   * it is initialized (singleton pattern).
   */
  useEffect(() => {
    if (
      workoutPlayer.workout &&
      workoutPlayer.exercises &&
      process.current.stage
    ) {
      if (!interval.current) {
        interval.current = setInterval(() => {
          if (process.current.changedManually) {
            process.current = {
              ...process.current,
              changedManually: false,
            };
            return; // Exit this iteration of the callback
          }
          // We increment the time by one second in the process ref NOT IN INTERVAL.
          process.current = {
            ...process.current,
            time: process.current.time + 1,
          };

          // We track the amount of time spent in an exercise in case we want to
          // skip the exercise we should do time = time  + (ex.totalTime - ex.trackTime)
          process.current.exercises[
            process.current.exerciseCounter
          ].time.trackTime =
            process.current.exercises[process.current.exerciseCounter].time
              .trackTime + 1;

          // We track the amount of time spent in an set in case we want to
          // skip the set we should do time = time  + (set.totalTime - set.trackTime)
          process.current.exercises[
            process.current.exerciseCounter
          ].execution.seriesDone[
            process.current.exercises[process.current.exerciseCounter].execution
              .seriesTrack - 1
          ].trackTime =
            process.current.exercises[process.current.exerciseCounter].execution
              .seriesDone[
              process.current.exercises[process.current.exerciseCounter]
                .execution.seriesTrack - 1
            ].trackTime + 1;

          if (process.current.stage === "countdown") {
            if (process.current.countdown < 1) {
              process.current = {
                ...process.current,
                stage: "duration",
              };
            } else {
              process.current = {
                ...process.current,
                countdown: process.current.countdown - 1,
              };
            }
          } else if (process.current.stage === "duration") {
            console.log("duration", process.current.duration);

            if (process.current.duration < 1) {
              process.current = {
                ...process.current,
                stage: "rest",
              };
            } else {
              process.current = {
                ...process.current,
                duration: process.current.duration - 1,
              };
            }
          } else if (process.current.stage === "rest") {
            console.log("REST", process.current.rest);
            if (process.current.rest < 1) {
              process.current = {
                ...process.current,
                countdown: process.current.countdownMax,
                rest: process.current.restMax,
                duration: process.current.durationMax,
                stage: "countdown",
              };
              console.log(
                "exerciseCounter execution PREV is :",
                process.current.exercises,
                process.current.exerciseCounter
              );
              let execution =
                process.current.exercises[process.current.exerciseCounter]
                  .execution;
              console.log(
                "exerciseCounter execution is :",
                process.current.exerciseCounter,
                execution
              );
              console.log("process.current pre ex", process.current);
              if (execution.seriesTrack >= execution.sets) {
                console.log("execution.seriesTrack >= execution.sets");
                // if there is another exercise
                if (
                  process.current.exercises[process.current.exerciseCounter + 1]
                ) {
                  process.current = {
                    ...process.current,
                    exerciseCounter: process.current.exerciseCounter + 1,
                    countdown:
                      process.current.exercises[process.current.exerciseCounter]
                        .time.countdownMax,
                    rest: process.current.exercises[
                      process.current.exerciseCounter
                    ].time.restMax,
                    duration:
                      process.current.exercises[process.current.exerciseCounter]
                        .time.durationMax,
                  };
                } else {
                  process.current = {
                    ...process.current,
                    stage: "success",
                  };
                  clearInterval(interval.current);
                }
              } else {
                let newObj = Object.assign({}, process.current);
                newObj.exercises[
                  process.current.exerciseCounter
                ].execution.seriesTrack =
                  newObj.exercises[process.current.exerciseCounter].execution
                    .seriesTrack + 1;
                process.current = {
                  ...process.current,
                  exercises: newObj.exercises,
                };
              }
            } else {
              process.current = {
                ...process.current,
                rest: process.current.rest - 1,
              };
            }
          }
          console.log("process.current pre render", process.current);

          setUpdateState(Math.random());
        }, [1000]);
      }
    }
  }, [
    process.current.stage,
    workoutPlayer.workout,
    workoutPlayer.exercises,
    updateInterval,
  ]);

  useEffect(() => {
    if (session === null) {
      fetchUserSession({
        userId: user.userId,
        setSession,
        setWorkoutPlayer,
        setUpdateState,
      });
    }
  }, [user.userId, session]);

  const handleNextSet = () => {
    let execution =
      process.current.exercises[process.current.exerciseCounter].execution;

    console.log("process.current pre ex", process.current);

    if (execution.seriesTrack < execution.sets) {
      const timeTrackAuxActualSet =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].trackTime;

      //Reset set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 1
      ].trackTime = 0;

      //Reset next set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[
          process.current.exerciseCounter
        ].execution.seriesTrack
      ].trackTime = 0;

      //Reset time tracking of countdown duration rest
      const exerciseTime =
        process.current.exercises[process.current.exerciseCounter].time;

      const timeToSum =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].totalTime - timeTrackAuxActualSet;

      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack + 1;

      process.current = {
        ...process.current,
        time: process.current.time + timeToSum + 1,
        stage: "countdown",
        countdown: exerciseTime.countdownMax,
        duration: exerciseTime.durationMax,
        rest: exerciseTime.restMax,
        changedManually: true,
      };

      setUpdateState(Math.random());
    }
  };
  const styleNextSet =
    process.current &&
    process.current.exercises &&
    process.current.exercises[process.current.exerciseCounter].execution
      .seriesTrack <
      process.current.exercises[process.current.exerciseCounter].execution.sets;

  const handlePrevSet = () => {
    let execution =
      process.current.exercises[process.current.exerciseCounter].execution;
    console.log(
      "exerciseCounter execution is :",
      process.current.exerciseCounter,
      execution
    );
    console.log("process.current pre ex", process.current);
    /**
     * TODO: complete prevSet
     */
    if (execution.seriesTrack > 1) {
      const timeTrackAuxActualSet =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].trackTime;

      const timeTrackAuxPreviousSet =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 2
        ].totalTime;

      //Reset set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 1
      ].trackTime = 0;

      //Reset next set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 2
      ].trackTime = 0;

      console.log(
        "test prev set: ",
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 2
        ]
      );

      //Reset time tracking of countdown duration rest
      const exerciseTime =
        process.current.exercises[process.current.exerciseCounter].time;

      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 1;

      process.current = {
        ...process.current,
        time:
          process.current.time -
          (timeTrackAuxPreviousSet + timeTrackAuxActualSet),
        stage: "countdown",
        countdown: exerciseTime.countdownMax,
        duration: exerciseTime.durationMax,
        rest: exerciseTime.restMax,
        changedManually: true,
      };

      setUpdateState(Math.random());
    }
  };
  const stylePrevSet =
    process.current &&
    process.current.exercises &&
    process.current.exercises[process.current.exerciseCounter].execution
      .seriesTrack > 1;

  const handleStopTraining = () => {
    process.current = {
      ...process.current,
      intervalActive: false,
    };
    clearInterval(interval.current);
    interval.current = null;
    setUpdateState(Math.random());
  };
  const handleResumeTraining = () => {
    process.current = {
      ...process.current,
      intervalActive: true,
    };
    setUpdateInterval(Math.random());
  };

  const handleCancelTraining = () => {
    let ans = window.confirm(
      "Are you sure to cancel the training? Progress will be lost."
    );
    console.log("ans", ans);
    if (ans) {
      clearInterval(interval.current);
      interval.current = null;
      session.finishWorkoutPlayer();
    }
  };

  const preHandleUploadTraining = () => {
    let ans = window.confirm(
      "Are you sure to upload the training? Timer will stop."
    );
    console.log("ans", ans);
    if (ans) {
      handleStopTraining();
      handleUploadTraining();
    }
  };

  const exerciseTrackVerification = () => {
    let valid = true;
    workoutPlayer.exercises.forEach((e) => {
      e.execution.seriesDone.forEach((s) => {
        if (isNaN(s.weight) || s.weight < 0) {
          valid = false;
          toast.error(`Weight can't be lower than 0.`);
        }
        if (isNaN(s.reps) || s.reps < 0) {
          valid = false;
          toast.error(`Reps can't be lower than 0.`);
        }
      });
    });
    return valid;
  };

  const handleUploadTraining = async () => {
    if (!exerciseTrackVerification()) {
      return;
    }
    let exercisePromises = [];
    const jwt = await jwtRefresher(session);

    workoutPlayer.exercises.forEach((e) => {
      let obj = {}; // Object to group series by weight

      e.execution.seriesDone.forEach((serDoneEl) => {
        const weight = serDoneEl.weight;
        const reps = serDoneEl.reps;
        if (reps < 1) {
          return; // This acts like 'continue' in the `forEach` loop
        }
        // If the weight group exists, accumulate reps
        if (obj[weight]) {
          obj[weight].totalReps += reps;
          obj[weight].count += 1;
        } else {
          // Initialize group for this weight
          obj[weight] = {
            weight: weight,
            totalReps: reps, // Start with the current reps
            count: 1,
            exercise_id: e.exercise_id,
          };
        }
      });

      console.log("obj is upload ece: ", obj);

      // After grouping, calculate the average reps for each weight group
      Object.keys(obj).forEach((weight) => {
        const group = obj[weight];
        const averageReps = group.totalReps / group.count;

        const finalObj = {
          jwt,
          weight: parseFloat(group.weight),
          reps: parseFloat(averageReps), // Average reps for this weight
          exercise_id: group.exercise_id,
        };

        console.log("Grouped exercise obj: ", finalObj);

        // Push the post request promise
        exercisePromises.push(postLastEce(finalObj));
      });
    });

    try {
      const res = await Promise.all(exercisePromises);
      console.log("training ", res);
      toast.success("The training was uploaded succesfully");
      clearInterval(interval.current);
      interval.current = null;
      session.finishWorkoutPlayer();
    } catch (error) {
      toast.error("The training was not uploaded succesfully. Try again later");

      console.log("training ERROR ", error);
    }
  };

  const rows = workoutPlayer.exercises;
  const stage = process.current.stage;
  const countdown = process.current.countdown;
  const duration = process.current.duration;
  const rest = process.current.rest;
  const countdownMax = process.current.countdownMax;
  const durationMax = process.current.durationMax;
  const restMax = process.current.restMax;
  const exerciseCounter = process.current.exerciseCounter;

  /**
   * I'm going to handle the next and previous exercise logic with the styles too.
   */
  const handlePreviousExercise = () => {
    if (process.current.exercises[process.current.exerciseCounter - 1]) {
      /**
       * TODO: CLEAR ACTUAL EXERCISE AND PREVIOUS EXERCISE TIME AND EXECTION | UPDATE PROCESS.COUN.DUR.REST TO THE PREVIOUYS
       */
      const timeTrackAuxPreviousEx =
        process.current.exercises[process.current.exerciseCounter - 1].time
          .totalTime;

      const timeTrackAuxActualEx =
        process.current.exercises[process.current.exerciseCounter].time
          .trackTime;

      //Reset previous exercise
      const prevTimeEx =
        process.current.exercises[process.current.exerciseCounter - 1].time;

      //Reset exercise time in set track
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      //Reset exercise time in set track in following
      process.current.exercises[
        process.current.exerciseCounter - 1
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter - 1
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      process.current.exercises[
        process.current.exerciseCounter - 1
      ].execution.seriesTrack = 1;
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack = 1;

      //Reset actual exercise time
      process.current.exercises[process.current.exerciseCounter].time = {
        ...process.current.exercises[process.current.exerciseCounter].time,
        trackTime: 0,
      };
      //Reset previous exercise time
      process.current.exercises[process.current.exerciseCounter - 1].time = {
        ...process.current.exercises[process.current.exerciseCounter - 1].time,
        trackTime: 0,
      };

      process.current = {
        ...process.current,
        exerciseCounter: process.current.exerciseCounter - 1,
        time:
          process.current.time -
          (timeTrackAuxPreviousEx + timeTrackAuxActualEx),
        stage: "countdown",
        countdown: prevTimeEx.countdownMax,
        duration: prevTimeEx.durationMax,
        rest: prevTimeEx.restMax,
        changedManually: true,
      };

      setUpdateState(Math.random());
    }
  };
  const previousExerciseStyle =
    process.current &&
    process.current.exerciseCounter &&
    process.current.exercises[process.current.exerciseCounter - 1] !== undefined
      ? true
      : false;

  const handleNextExercise = () => {
    if (process.current.exercises[process.current.exerciseCounter + 1]) {
      const timeTrackAuxActualEx =
        process.current.exercises[process.current.exerciseCounter].time
          .trackTime;

      const nextTimeEx =
        process.current.exercises[process.current.exerciseCounter + 1].time;

      //Reset exercise track time
      process.current.exercises[process.current.exerciseCounter].time = {
        ...process.current.exercises[process.current.exerciseCounter].time,
        trackTime: 0,
      };
      //Reset exercise track time
      process.current.exercises[process.current.exerciseCounter + 1].time = {
        ...process.current.exercises[process.current.exerciseCounter + 1].time,
        trackTime: 0,
      };

      //Reset exercise time in set track
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      //Reset exercise time in set track in following
      process.current.exercises[
        process.current.exerciseCounter + 1
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter + 1
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack = 1;

      process.current.exercises[
        process.current.exerciseCounter + 1
      ].execution.seriesTrack = 1;

      const timeToSum =
        process.current.exercises[process.current.exerciseCounter].time
          .totalTime - timeTrackAuxActualEx;

      process.current = {
        ...process.current,
        exerciseCounter: process.current.exerciseCounter + 1,
        time: process.current.time + timeToSum,
        stage: "countdown",
        changedManually: true,
        countdown: nextTimeEx.countdownMax,
        duration: nextTimeEx.durationMax,
        rest: nextTimeEx.restMax,
      };
      console.log(
        "process.current.exercises[process.current.exerciseCounter] clear",
        process.current.exercises[process.current.exerciseCounter]
      );
      setUpdateState(Math.random());
    }
  };
  const nextExerciseStyle =
    process.current &&
    process.current.exercises &&
    process.current.exercises[process.current.exerciseCounter + 1]
      ? true
      : false;

  console.log(
    "asd",
    nextExerciseStyle,
    process.current.exercises,
    process.current.exerciseCounter,
    process.current.exerciseCounter + 1,
    process.current.exercises &&
      process.current.exercises[process.current.exerciseCounter + 1]
  );

  console.log("workoutPlayer ", workoutPlayer);

  return (
    <SessionContext.Provider value={session} id={"asd"}>
      {children}
      {workoutPlayer.workout && (
        <Box
          sx={{
            width: "100%",
            height: "100dvh",
            backgroundColor: "white",
            position: "fixed",
            display: "column",
            overflowY: "auto",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={(process.current.time / process.current.totalTime) * 100}
            sx={{ mb: 1, position: "sticky", top: 0, zIndex: 150 }}
            color="inherit"
          />

          <Typography
            sx={{
              fontSize: { xs: 30, sm: 50 },
              mb: { xs: 2, sm: 5 },
              textAlign: "center",
            }}
            pl={2}
            fontWeight="bold"
          >
            {t("workTra")}
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                component="img"
                src={IMG_BASE_URL + workoutPlayer.workout.photo_url}
                sx={{
                  height: "140px",
                  width: "140px",
                  maxHeight: "140px",
                  maxWidth: "140px",
                  mb: 1,
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  objectFit: "cover",
                }}
              ></Box>
              <Box>
                <Typography variant="h4" fontWeight={"bold"}>
                  {workoutPlayer.workout.name}
                </Typography>
                <Typography
                  sx={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  {Math.round((process.current.totalTime / 60) * 100) / 100} Min
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 3, sm: 0 },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "black",
                  color: "white",
                  height: "3rem",
                  ":hover": { background: "black", color: "white" },
                  width: "100px",
                }}
                onClick={handleCancelTraining}
              >
                {t("cancel")} <TrendingDownIcon fontSize="medium" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "black",
                  color: "white",
                  height: "3rem",
                  ":hover": { background: "black", color: "white" },
                  paddingX: 0,
                  width: "100px",
                }}
                onClick={preHandleUploadTraining}
              >
                {t("upload")} <TrendingUpIcon fontSize="medium" />
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              p: 2,
            }}
          >
            {workoutPlayer.workout.description && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="p" fontWeight={"bold"}>
                  {t("desc")}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    whiteSpace: "pre-line",
                    maxHeight: "200px",
                    overflowY: "auto",
                    px: 1,
                  }} // Handles \n as line breaks
                >
                  {workoutPlayer.workout.description}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ display: { xs: "none", sm: "flex" }, p: 1 }}>
            {workoutPlayer.exercises && (
              <WorkoutExerciseTableExecution
                rows={rows}
                setWorkoutPlayer={setWorkoutPlayer}
                workoutPlayer={workoutPlayer}
                stage={stage}
                countdown={countdown}
                duration={duration}
                rest={rest}
                countdownMax={countdownMax}
                durationMax={durationMax}
                restMax={restMax}
                exerciseCounter={exerciseCounter}
              />
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              flexDirection: "column",
              mt: { xs: 2, sm: 5 },
              rowGap: 2,
              p: 1,
            }}
          >
            {workoutPlayer.exercises && workoutPlayer.exercises.length > 0 ? (
              workoutPlayer.exercises.map((row, photoIndex) => {
                console.log("photoIndex ", photoIndex);
                console.log(
                  "workout ex",
                  workoutPlayer.exercises,
                  workoutPlayer.exercises[photoIndex]
                );
                return (
                  <Box
                    key={row.exercise_id}
                    sx={{
                      gap: 1,
                      display: "flex",
                      flexDirection: "column",
                      "&:last-child td, &:last-child th": { border: 0 },
                      borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                      background:
                        photoIndex === exerciseCounter
                          ? "rgba(0,0,0,0.06)"
                          : "",
                    }}
                  >
                    {photoIndex === exerciseCounter && (
                      <Box sx={{ position: "relative", overflow: "hidden" }}>
                        <Box sx={{ overflow: "hidden" }}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              stage === "countdown"
                                ? (countdown / countdownMax) * 100
                                : stage === "duration"
                                ? (duration / durationMax) * 100
                                : (rest / restMax) * 100
                            }
                            color={
                              stage === "countdown"
                                ? "warning"
                                : stage === "duration"
                                ? "error"
                                : "info"
                            }
                          />
                        </Box>
                      </Box>
                    )}

                    <Box sx={{ display: "flex", p: 1, gap: 1 }}>
                      {/* Media Slider */}
                      <Box>
                        <Swiper
                          modules={[Navigation, Pagination]}
                          pagination={{ clickable: true }}
                          spaceBetween={10}
                          slidesPerView={1}
                          style={{
                            width: "150px",
                            height: "150px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "0.3rem",
                            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
                          }}
                        >
                          {/* Map through media (images and video) */}
                          {[
                            ...(row.photo_arr ? row.photo_arr : []),
                            ...(row.video_arr ? [row.video_arr[0]] : []),
                          ].map((item, index) => (
                            <SwiperSlide key={index}>
                              {item.includes("video") ? (
                                <Box
                                  component="video"
                                  controls
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    zIndex: -1,
                                  }}
                                >
                                  <source
                                    src={IMG_BASE_URL + item}
                                    type="video/mp4"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                  Your browser does not support the video tag.
                                </Box>
                              ) : (
                                <Box
                                  component="img"
                                  src={IMG_BASE_URL + item}
                                  alt={`Media ${index}`}
                                  sx={{
                                    width: "150px",
                                    height: "150px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="h5">
                          {photoIndex + 1 + ") "} {row.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          <Typography>{row.execution.sets} sets</Typography> ·
                          <Typography>RIR {row.execution.rir}</Typography> ·
                          <Typography>
                            {row.execution.reps.min === row.execution.reps.max
                              ? row.execution.reps.min
                              : `${row.execution.reps.min} - ${row.execution.reps.max}`}{" "}
                            Reps
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxHeight: 60,
                        overflowY: "auto",
                        p: 1,
                      }}
                    >
                      <Typography> {row.execution.details}</Typography>
                    </Box>
                    {/* TODO: CONVERT TIMER TO ICON TO BE EASIER TO READ TO CUSTOMER
                     */}
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                        alignItems: "center",
                        gap: 2,
                        p: 1,
                      }}
                    >
                      <Box>
                        {photoIndex === exerciseCounter &&
                        stage === "countdown" ? (
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            color={"warning"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            borderBottom={"1px solid gray"}
                            height={25}
                          >
                            <AlarmIcon />
                            {countdown}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            height={25}
                          >
                            <AlarmIcon />
                            {row.time.countdown}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        {photoIndex === exerciseCounter &&
                        stage === "duration" ? (
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            borderBottom={"1px solid gray"}
                            height={25}
                          >
                            <TimelapseIcon /> {duration}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            height={25}
                          >
                            <TimelapseIcon />
                            {row.time.duration}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        {photoIndex === exerciseCounter && stage === "rest" ? (
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            color={"info"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            borderBottom={"1px solid gray"}
                            height={25}
                          >
                            <BedtimeIcon />
                            {rest}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            height={25}
                          >
                            <BedtimeIcon /> {row.time.rest}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "space-around",
                        p: 1,
                        pb: 2,
                        overflowX: "auto",
                      }}
                    >
                      {new Array(rows[photoIndex].execution.sets)
                        .fill(1)
                        .map((e, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "space-between",
                              alignItems: "center",
                              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
                              background: "rgba(0,0,0,0.1)",
                              borderTopRightRadius: "0.5rem",
                              borderBottomRightRadius: "0.5rem",
                              border:
                                rows[photoIndex].execution.seriesTrack ===
                                  index + 1 && "1px solid black",
                              borderLeft:
                                rows[photoIndex].execution.seriesTrack ===
                                index + 1
                                  ? "5px solid black"
                                  : "2px solid black",

                              p: 1,
                            }}
                          >
                            <Typography
                              color="white"
                              textAlign={"center"}
                              variant="h6"
                              sx={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // Add black shadow with slight blur
                              }}
                            >
                              Set {index + 1}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              <TextField
                                sx={{
                                  zIndex: 0,

                                  background: "white",
                                  width: "90px",
                                }}
                                defaultValue={
                                  workoutPlayer.exercises[photoIndex].execution
                                    .seriesDone[index].weight
                                }
                                onChange={(e) => {
                                  setWorkoutPlayer((prev) => {
                                    let workoutPlayerCopy = Object.assign(
                                      {},
                                      prev
                                    );

                                    workoutPlayerCopy.exercises[
                                      photoIndex
                                    ].execution.seriesDone[index].weight =
                                      parseInt(e.target.value);

                                    return workoutPlayerCopy;
                                  });
                                }}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      Kg
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                color="primary"
                                sx={{
                                  width: "90px",
                                  background: "white",
                                }}
                                defaultValue={
                                  workoutPlayer.exercises[photoIndex].execution
                                    .seriesDone[index].reps
                                }
                                onChange={(e) => {
                                  setWorkoutPlayer((prev) => {
                                    let workoutPlayerCopy = Object.assign(
                                      {},
                                      prev
                                    );

                                    workoutPlayerCopy.exercises[
                                      photoIndex
                                    ].execution.seriesDone[index].reps =
                                      parseInt(e.target.value);

                                    return workoutPlayerCopy;
                                  });
                                }}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      Rep
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Typography textAlign={"center"} color={"rgba(0,0,0,0.4)"}>
                No content
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              position: "sticky",
              bottom: "0",
              background: "rgba(255, 255, 255, 0.4)", // Slightly transparent white background
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              backdropFilter: "blur(10px)", // Apply blur effect to the background
              WebkitBackdropFilter: "blur(10px)", // Safari support
              zIndex: 100,
            }}
          >
            {process.current.stage === "success" ? (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "black",
                  color: "white",
                  height: "3rem",
                  ":hover": { background: "black", color: "white" },

                  gap: 0.5,
                  my: 1,
                }}
                onClick={handleUploadTraining}
              >
                <UploadIcon /> Upload training
              </Button>
            ) : (
              <>
                <SkipPreviousIcon
                  fontSize="large"
                  sx={{
                    width: "40px",
                    height: "40px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: stylePrevSet ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handlePrevSet}
                />
                <SkipPreviousIcon
                  fontSize="large"
                  sx={{
                    width: "50px",
                    height: "50px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: previousExerciseStyle ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handlePreviousExercise}
                />
                {process.current.intervalActive ? (
                  <PauseCircleIcon
                    sx={{
                      width: "60px",
                      height: "60px",
                      ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    }}
                    onClick={handleStopTraining}
                  />
                ) : (
                  <PlayCircleIcon
                    sx={{
                      width: "60px",
                      height: "60px",
                      ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    }}
                    onClick={handleResumeTraining}
                  />
                )}
                <SkipNextIcon
                  fontSize="large"
                  sx={{
                    width: "50px",
                    height: "50px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: nextExerciseStyle ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handleNextExercise}
                />
                <SkipNextIcon
                  fontSize="large"
                  sx={{
                    width: "40px",
                    height: "40px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: styleNextSet ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handleNextSet}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
