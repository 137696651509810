import { Box, CircularProgress, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import MainHeader from "../../components/MainHeader";
import { useUserSession } from "../../context/SessionProvider";
import CreateEditUsername from "../../components/CreateEditUsername";
import CreateEditCountry from "../../components/CreateEditCountry";
import AdSense from "../../components/AdSense";
import { useEffect } from "react";
import { assignContentCreator } from "../Settings/Creator";
import { useTranslation } from "react-i18next";

const Root = () => {
  const { t, i18n } = useTranslation(["root"]);
  const session = useUserSession();
  const navigate = useNavigate();
  console.log("session root> ", session);

  useEffect(() => {
    if (session) {
      // Extract the query parameters from the URL
      const referralCodeFromLocalHost = localStorage.getItem("referralCode");

      if (
        referralCodeFromLocalHost &&
        session &&
        !session.customer.creator_id
      ) {
        assignContentCreator(
          referralCodeFromLocalHost,
          false,
          session,
          true
        ).then((e) => {
          localStorage.removeItem("referralCode");
        });
      }
    }
  }, [session]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh",
        position: "fixed",
        padding: { xs: 0, md: 1 },
        gap: 1,
        display: "flex",
        flexDirection: {
          xs: "column-reverse", // Hide on extra small screens
          sm: "row", // Show as flex on medium screens and up
        },
      }}
    >
      <AdSense />
      <MainHeader />
      {!session ? (
        <Box px={1} sx={{ height: "100%" }}>
          <CircularProgress size={30} sx={{ alignSelf: "center" }} />
        </Box>
      ) : session &&
        session?.customer?.username &&
        session?.customer?.country_id ? (
        <Box
          component="main"
          sx={{
            flexGrow: "1",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            overflow: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Outlet />
        </Box>
      ) : (
        <Box px={1} sx={{ height: "100%" }}>
          <Typography variant="h3" fontWeight="bold" mb={3}>
            {t("completeInfo")}
          </Typography>
          <CreateEditUsername
            optional={session?.customer?.username}
            username={session?.customer?.username}
          />
          <CreateEditCountry
            optional={session?.customer?.country_id}
            country_id={session?.customer?.country_id}
          />
        </Box>
      )}
    </Box>
  );
};

export default Root;
